import React from "react";
import styled from "styled-components";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import {Section, Text, MainTitle, Title, Button} from "../components/Core";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";
import FreshStreamWidget from "../sections/careers/FreshStreamWidget";
import employees from "../assets/image/png/employees.png";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { navigate } from 'gatsby';

import allForOne from "../assets/image/rebrand/AllForOne.svg";
import dare from "../assets/image/rebrand/Dare.svg";
import haveFun from "../assets/image/rebrand/HaveFun.svg";

import annualLeave from "../assets/image/png/AnnualLeave.png";
import broadband from "../assets/image/png/Broadband.png";
import shares from "../assets/image/png/Shares.png";
import discounts from "../assets/image/png/Discounts.png";
import bonus from "../assets/image/png/Bonus.png";
import remote from "../assets/image/png/Remote.png";

import inside1 from "../assets/image/jpeg/inside-netomnia-photo-1.jpg";
import inside2 from "../assets/image/jpeg/inside-netomnia-photo-2.jpg";
import inside3 from "../assets/image/jpeg/inside-netomnia-photo-3.jpg";

import zoltan from "../assets/image/jpeg/zoltan.jpg";
import aaron from "../assets/image/jpeg/aaron.jpg";

import stage1icon from "../assets/image/rebrand/stage-1-icon.svg";
import stage2icon from "../assets/image/rebrand/stage-2-icon.svg";
import stage3icon from "../assets/image/rebrand/stage-3-icon.svg";

import whiteLine from "../assets/image/rebrand/white-line.jpg";


const StyledButton = styled(Button)`
    background: #fff !important;
    color: #ce0390;
    border-radius: 100px;
`

const StyledText = styled(Text)`
    color: #fff;
    font-style: normal !important;
    i {
        font-style: italic !important;
    }
`

const StyledSubtitle = styled(Title)`
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    font-size: 50px;
`

const Careers = () => {

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        768: { items: 2 },
        1024: { items: 3 },
    };

    const responsiveInside = {
        0: { items: 1 }
    };

    const itemsHowWeWork = [
        <>
            <div className="center-items pb-3">
                <img src={allForOne} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">All for One.</h3>
                <hr />
                <p className="px-3">
                    A fast growing team can often lose the sight of collaboration.
                    Not us. Every action you take helps drive everyone forward;
                    from our senior management to our newest recruit.
                    The team has your back, just like we know you’ll have ours.
                </p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={dare} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Dare to be You.</h3>
                <hr />
                <p className="px-3">
                    The individuals are what comprises a company. Your unique and personal
                    insights can spark organisation-wide change. We continually look for
                    more efficient ways to work, so challenge the status quo, ask questions and remain agile.
                </p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={haveFun} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Have Fun &<br />Get Sh*t Done.</h3>
                <hr />
                <p className="px-3">
                    Whilst our focus is continually on the horizon, we also understand
                    the importance of enjoying the little things that happen to us daily.
                    Laugh with your team, love what you do and ensure that we keep moving towards our long-term goals.
                </p>
            </div>
        </>
    ];

    const itemsTheExtras = [
        <>
            <div className="center-items pb-3">
                <img src={annualLeave} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">25 Days<br />Annual Leave</h3>
                <hr />
                <p className="px-3">Hard work needs even harder recovery. 25 Days annual leave for you to use as you desire + bank holidays!</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={broadband} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Free Broadband</h3>
                <hr />
                <p className="px-3">If you live within our build network you will receive free broadband from our ISP partners. Friends and family can also receive discounted prices!</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={shares} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Company<br />Shares</h3>
                <hr />
                <p className="px-3">Your actions will directly contribute to our success, so you should reap the rewards. You’ll receive shares in Netomnia, just like the rest of the team.</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={discounts} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Gym and retail<br />discounts</h3>
                <hr />
                <p className="px-3">For gym lovers and shopaholics, you can take advantage of discounted prices at popular retail/gym brands.</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={bonus} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Discretionary annual<br />bonus (up to 30%!)</h3>
                <hr />
                <p className="px-3">Receive a bonus of up to 30% your salary if we hit our targets and smash our goals. The best part? This opportunity happens every year!</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={remote} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Remote<br />working</h3>
                <hr />
                <p className="px-3">The best people don’t always live on our doorstep, that’s why we’re a completely remote workforce. Our HQ is based in Tewkesbury which may require occasional travel should your team need a central place to collaborate.</p>
            </div>
        </>
    ];

    const itemsTheExtras2 = [
        <div className="extras">
            <div className="center-items pb-3">
                <img src={annualLeave} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">25 Days<br />Annual Leave</h3>
                <hr />
                <p className="px-3">Hard work needs even harder recovery. 25 Days annual leave for you to use as you desire + bank holidays!</p>
            </div>
            <div className="center-items pb-3">
                <img src={broadband} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Free Broadband</h3>
                <hr />
                <p className="px-3">If you live within our build network you will receive free broadband from our ISP partners. Friends and family can also receive discounted prices!</p>
            </div>
            <div className="center-items pb-3">
                <img src={shares} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Company<br />Shares</h3>
                <hr />
                <p className="px-3">Your actions will directly contribute to our success, so you should reap the rewards. You’ll receive shares in Netomnia, just like the rest of the team.</p>
            </div>
        </div>,
        <div className="extras">
            <div className="center-items pb-3">
                <img src={discounts} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Gym and retail<br />discounts</h3>
                <hr />
                <p className="px-3">For gym lovers and shopaholics, you can take advantage of discounted prices at popular retail/gym brands.</p>
            </div>
            <div className="center-items pb-3">
                <img src={bonus} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Discretionary annual<br />bonus (up to 30%!)</h3>
                <hr />
                <p className="px-3">Receive a bonus of up to 30% your salary if we hit our targets and smash our goals. The best part? This opportunity happens every year!</p>
            </div>
            <div className="center-items pb-3">
                <img src={remote} className="icon" alt="Netomnia" height={50}/>
                <h3 className="my-4">Remote<br />working</h3>
                <hr />
                <p className="px-3">The best people don’t always live on our doorstep, that’s why we’re a completely remote workforce. Our HQ is based in Tewkesbury which may require occasional travel should your team need a central place to collaborate.</p>
            </div>
        </div>
    ];

    const itemsInside = [
        <img src={inside1} className="slide" alt="Netomnia" />,
        <img src={inside2} className="slide" alt="Netomnia" />,
        <img src={inside3} className="slide" alt="Netomnia" />
    ];

    const data = useStaticQuery(graphql`
        query strapiCareersPageQuery {
            strapiCareersPage {
                title
                subtitle_1
                text_1
                subtitle_2
                text_2
                no_available_jobs_text
                application_text
            }
            strapiPageHeadings(slug: {eq: "careers"}) {
                h1
            }
        }
    `);

    const pageData = getProperty(data, 'strapiCareersPage');
    const headingsData = getProperty(data, 'strapiPageHeadings');

    return (
        <div className="no-padding-wrapper careers">
            <Seo page="careers"/>
            <PageWrapper footerDark>
                <Section className="careers-feature pb-5">
                    <Container>
                        <Row>
                            <Col sm="8" lg="5" className="justify-content-center mb-5 pb-5">
                                <MainTitle variant="hero">Take up the challenge</MainTitle>
                                <Text
                                    as="h4"
                                    color="heading"
                                    fontSize="1.7em"
                                    fontWeight={500}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="mb-5 mt-0"
                                >
                                    Are you ready to unleash<br />
                                    your true potential?
                                </Text>
                                <StyledButton
                                    className="mx-auto"
                                    onClick={() => navigate('/careers/#jobs')}
                                >
                                    View Jobs
                                </StyledButton>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="careers-we-are">
                    <Container>
                        <Row>
                            <Col sm="12" md="12" lg="7" >
                                <Title
                                    as="h2"
                                    color="heading"
                                    fontSize="1.7em"
                                    fontWeight={600}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="mb-5 mt-0"
                                >
                                    We’re Netomnia
                                </Title>
                                <Text>
                                    Founded in 2019, we’re the fastest-growing networking operator in the
                                    UK, specialising in connecting homes and businesses to ultrafast full
                                    fibre broadband.
                                </Text>
                                <Text>
                                    The digital world is revolutionising daily. We believe that giving access to
                                    the latest broadband technology to hundreds of thousands of users will
                                    allow them to leverage online resources and unleash their potential.
                                </Text>
                                <Text>
                                    We’re empowering generations to come to discover their passions,
                                    refine skills and pursue interests with ease through a seamless
                                    connection to all of the assets the Internet has to offer.
                                </Text>
                            </Col>
                            <Col sm="12" md="12" lg="5" className="d-flex align-items-end">
                                <img src={employees} alt="Netomnia Careers" />
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="careers-work pb-5">
                    <Container>
                        <Row>
                            <Col lg="12" className="justify-content-center text-center">
                                <Title
                                    as="h2"
                                    color="#ce0390"
                                    fontSize="1.7em"
                                    fontWeight={600}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="mb-5 mt-0"
                                >
                                    How we work
                                </Title>
                                <Text>
                                    We have three core values that are the driving force behind how we work:
                                </Text>
                                <iframe className="ytPlayer mt-5" frameborder="0" allowfullscreen="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="Netomnia" src="https://www.youtube.com/embed/ZyyQIxF2mc0?autoplay=0&amp;playsinline=1&amp;start=1&amp;enablejsapi=1&amp;controls=0&amp;fs=0&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;disablekb=1&amp;playlist=ZyyQIxF2mc0&amp;widgetid=1"></iframe>
                            </Col>
                            <Col lg="12" className="bottom-background justify-content-center text-center">
                                <AliceCarousel
                                    mouseTracking
                                    responsive={responsive}
                                    disableButtonsControls={true}
                                    infinite={false}
                                    items={itemsHowWeWork}
                                    controlsStrategy="alternate" />
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <div className="careers-testimonial">
                    <Container className="zoltanBackground">
                        <Row>
                            <Col sm="12" lg="6" className="pt-5 mt-5 pb-5 mb-3 d-flex align-items-start justify-content-center flex-column">
                                <Text><i>“We work within an ever-evolving industry that changes daily.</i></Text>
                                <Text>
                                    <i>The Netomnia team that we’ve built contain the most
                                    knowledgeable people in the sector. We know that having
                                    the right people join our robust workforce will only make
                                    us stronger and help us achieve our goals faster.</i>
                                </Text>
                                <Text>
                                    <i>If you thrive when taking up a challenge, then I highly
                                    encourage you to join us, the fastest-growing network
                                    operator in the country.”</i>
                                </Text>
                                <StyledText>
                                    Zoltan Kovacs,<br />
                                    <i>Managing Director</i>
                                </StyledText>
                                <StyledButton
                                    onClick={() => navigate('/careers/#jobs')}
                                    className="mt-4"
                                >
                                    View Jobs
                                </StyledButton>
                            </Col>
                            <Col sm="12" md="12" lg="0" className="d-flex align-items-end justify-content-center d-lg-none d-md-flex">
                                <img src={zoltan} alt="Netomnia Careers" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Section className="careers-deal pb-5">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5 justify-content-center text-center">
                                <Title
                                    as="h2"
                                    color="heading"
                                    fontSize="1.7em"
                                    fontWeight={600}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="mb-5 mt-0"
                                >
                                    The extras
                                </Title>
                                <Text>
                                    We understand that work isn’t everything and that our lives are complex and rich with family,
                                    friends, hobbies and interests.
                                </Text>
                                <Text>
                                    That’s why we offer an excellent benefits package which we are constantly improving and
                                    building on to help our team be at their best.
                                </Text>
                            </Col>
                            <Col lg="12" className="mt-5 justify-content-center text-center d-none d-lg-block">
                                <AliceCarousel
                                    mouseTracking
                                    responsive={responsiveInside}
                                    disableButtonsControls={true}
                                    infinite={true}
                                    items={itemsTheExtras2}
                                    controlsStrategy="alternate" />
                            </Col>
                            <Col lg="12" className="mt-5 justify-content-center text-center d-block d-lg-none">
                                <AliceCarousel
                                    mouseTracking
                                    responsive={responsive}
                                    disableButtonsControls={true}
                                    infinite={true}
                                    items={itemsTheExtras}
                                    controlsStrategy="alternate" />
                            </Col>
                        </Row>
                    </Container>
                </Section>

                <Section style={{ background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)' }}>
                    <Container>
                        <Row>
                            <Col sm={12} className="pb-5">
                                <Title className="text-center white-color">How we hire</Title>
                                <p className="white-color text-center">
                                    Not knowing what is around the corner in an interview process can be scary. We are completely
                                    transparent and want you to succeed. After all, we are looking for great people to support us on our journey.
                                </p>
                                <p className="white-color text-center">
                                    Here's everything you need to know about how we hire, so you'll be set for success:
                                </p>
                            </Col>
                        </Row>
                        
                        <Row className="vertical-center mb-5">
                            <Col lg={5} className="flex green-bordered align-items-center">
                                <img src={stage1icon} className="big-icon mr-5" alt="Netomnia" />
                                <StyledSubtitle>Stage 1</StyledSubtitle>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block align-self-center">
                                <img src={whiteLine} width={3} />
                            </Col>
                            <Col lg={6}>
                                <h3 className="white-color mt-xs-3">Let’s get acquainted!</h3>
                                <p className="white-color">
                                    Your first call is with one of our dedicated recruiters.
                                    We’ll discuss the role, your background, and what
                                    it’s like to work at Netomnia. Be ready to chat about
                                    your experience and ask questions—curiosity is encouraged!
                                </p>
                            </Col>
                        </Row>
                        <Row className="vertical-center mb-5">
                            <Col lg={5} className="flex green-bordered align-items-center">
                                <img src={stage2icon} className="big-icon mr-5" alt="Netomnia" />
                                <StyledSubtitle>Stage 2</StyledSubtitle>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block align-self-center">
                                <img src={whiteLine} width={3} />
                            </Col>
                            <Col lg={6}>
                                <h3 className="white-color mt-xs-3">The experience you’ll bring to Netomnia</h3>
                                <p className="white-color">
                                    Meet your potential manager to dive deeper into your experience
                                    and how it aligns with our team’s needs. You’ll discuss specific
                                    projects and might meet future colleagues. This stage usually
                                    concludes the process, but some roles may have a third step.
                                </p>
                            </Col>
                        </Row>
                        <Row className="vertical-center">
                            <Col lg={5} className="flex green-bordered align-items-center">
                                <img src={stage3icon} className="big-icon mr-5" alt="Netomnia" />
                                <StyledSubtitle>Stage 3</StyledSubtitle>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block align-self-center">
                                <img src={whiteLine} width={3} />
                            </Col>
                            <Col lg={6}>
                                <h3 className="white-color mt-xs-3">The Final Evaluation</h3>
                                <p className="white-color">
                                    If needed, you’ll have a relaxed conversation with our leadership team.
                                    It’s a last chance for both sides to ask any remaining questions
                                    before a decision is made.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col sm={12}>
                                <p className="white-color small text-center" style={{ lineHeight: 1.4, fontWeight: 300 }}>
                                    We are committed to fostering a workplace culture where equality, diversity, and inclusion (EDI) are core values. We believe that a diverse team—rich in background, experience, and
                                    perspectives—leads to stronger ideas and innovation. We welcome individuals of all ages, genders, races, ethnicities, sexual orientations, abilities, and experiences to apply. If you require any
                                    reasonable adjustments for your interview, please contact us at <a className="white-color" href="mailto:recruitment@substantial.group">recruitment@substantial.group</a>.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Section>

                <Section className="careers-inside pt-5 pb-0 justify-content-center text-center">
                    <Title
                        as="h2"
                        color="heading"
                        fontSize="1.7em"
                        fontWeight={600}
                        letterSpacing={-0.75}
                        mb={0}
                        className="mb-5 mt-0"
                    >
                        Inside Netomnia
                    </Title>
                    <AliceCarousel
                        responsive={responsiveInside}
                        disableButtonsControls={false}
                        infinite={true}
                        items={itemsInside}
                        autoPlay={false}
                        autoPlayInterval="3000"
                        disableDotsControls={true}
                        controlsStrategy="alternate" />
                </Section>
                <div className="careers-testimonial">
                    <Container>
                        <Row>
                            <Col sm="12" md="12" lg="6" className="pt-5 pb-5 d-flex align-items-start justify-content-center flex-column">
                                <Text>
                                    “I have found Netomnia to be full of enthusiastic,
                                    driven people who welcome innovation. It's a haven
                                    of collaboration, shared purpose and transparency.
                                    If you're looking to make a real impact and challenge
                                    the norm, this is the ideal organisation to be a part
                                    of. Joining Netomnia has been a game-changing
                                    career decision for me. I can confidently say that
                                    it's a company I'm proud to be a part of.”
                                </Text>
                                <StyledText>Michael Lee,
                                <br /><i>Data Analyst</i></StyledText>
                            </Col>
                            <Col sm="12" md="12" lg="6" className="d-flex align-items-end justify-content-center">
                                <img src={aaron} alt="Netomnia Careers" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Section id="jobs" className="careers-offers">
                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <Title
                                    as="h2"
                                    color="heading"
                                    fontSize="1.7em"
                                    fontWeight={600}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="mb-5 mt-0"
                                >
                                    Current opportunities
                                </Title>
                                <FreshStreamWidget />
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </div>
    )
}

export default Careers;
